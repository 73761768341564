import React from 'react'
import CountUp from 'react-countup';

export default function Review() {
  return (
    <div className="ak-primary-bg">
        <div className="ak-height-65 ak-height-lg-50"></div>
        <div className="container auto-counter-section">
          <div className="row align-items-center gap-lg-0">
            <div className="col-lg-4">
              <div className="ak-funfact ak-style1" data-aos="fade-up">
                <div className="ak-funfact-number color-1">
                  <div>
                    <span className='ak-stroke-number color-white counter'><CountUp delay={2} end={6} /></span>
                  </div>
                  <div><span className="ak-stroke-number color-white">+</span></div>
                </div>
                <div className="ak-funfact-text">
                  <p>YEARS OF SERVICE</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="ak-funfact ak-style1" data-aos="fade-up" data-aos-delay="100">
                <div className="ak-funfact-number">
                  <div>
                    <span className='ak-stroke-number color-white counter'><CountUp delay={2} end={1000} /></span>
                  </div>
                  <div><span className="ak-stroke-number color-white">+</span></div>
                </div>

                <div className="ak-funfact-text">
                  <p>HAPPY CUSTOMERS</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="ak-funfact ak-style1" data-aos="fade-up" data-aos-delay="150">
                <div className="ak-funfact-number">
                  <div>
                    <span className='ak-stroke-number color-white counter'><CountUp delay={2} end={1000} /></span>
                  </div>
                  <div><span className="ak-stroke-number color-white">+</span></div>
                </div>
                <div className="ak-funfact-text">
                  <p>CUSTOMER SATISFACTION</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ak-height-65 ak-height-lg-50"></div>
      </div>
  )
}
