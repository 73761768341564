import React, { useEffect, useState } from 'react'
import InnerBanner from '../components/InnerBanner/InnerBanner'
import Fancybox from '../components/FancyBox'
import { useDispatch, useSelector } from 'react-redux'
import { galleryItems } from '../redux/commonReducer'
import { getAllGallery } from '../redux/actionCreator'
import { API_BASE_IMAGE_URL } from '../constants/configuration'

function Gallery() {
  // const dispatch = useDispatch()

  // const galleryData = useSelector(galleryItems)

  // useEffect(() => {
  //   dispatch(getAllGallery())
  // }, [])
  
  const [visibleItems, setVisibleItems] = useState(6); // Start by showing 6 items
  const galleryLists = [
    { img: "/assets/img/gallery/24.jpg" },
    { img: "/assets/img/gallery/1.jpg" },
    { img: "/assets/img/gallery/2.jpg" },
    { img: "/assets/img/gallery/3.jpg" },
    { img: "/assets/img/gallery/4.jpg" },
    { img: "/assets/img/gallery/5.jpg" },
    // { img: "/assets/img/gallery/6.jpg" },
    // { img: "/assets/img/gallery/7.jpg" },
    // { img: "/assets/img/gallery/8.jpg" },
    { img: "/assets/img/gallery/9.jpg" },
    // { img: "/assets/img/gallery/10.jpg" },
    { img: "/assets/img/gallery/11.jpg" },
    { img: "/assets/img/gallery/12.jpg" },
    // { img: "/assets/img/gallery/13.jpg" },
    // { img: "/assets/img/gallery/14.jpg" },
    // { img: "/assets/img/gallery/15.jpg" },
    // { img: "/assets/img/gallery/16.jpg" },
    { img: "/assets/img/gallery/17.jpg" },
    { img: "/assets/img/gallery/18.jpg" },
    { img: "/assets/img/gallery/19.jpg" },
    { img: "/assets/img/gallery/20.jpg" },
    { img: "/assets/img/gallery/21.jpg" },
    { img: "/assets/img/gallery/22.jpg" },
    { img: "/assets/img/gallery/23.jpg" },
   
    // { img: "/assets/img/gallery/25.jpg" },
    // { img: "/assets/img/gallery/26.jpg" },
    // { img: "/assets/img/gallery/27.jpg" },
    // { img: "/assets/img/gallery/28.jpg" },
    // { img: "/assets/img/gallery/29.jpg" },
    // { img: "/assets/img/gallery/30.jpg" },
    // { img: "/assets/img/gallery/31.jpg" },
  ];

  const loadMoreItems = () => {
    setVisibleItems((prev) => prev + 6); // Load 6 more items
  };
 
  return (
    <>
      <InnerBanner title='Gallery' image='assets/img/about.jpeg' />
      <Fancybox>
        <div className="ak-height-100 ak-height-lg-50"></div>
        <div className="container">
          <div className="sidebar width-none">
            <div className="services-content">
              <div className="ak-section-heading ak-style-1">
                <div className="background-text" data-aos="fade-right" data-aos-duration="1000">Gallery</div>
                <h2 className="ak-section-title">Gallery</h2>
                <p className="ak-section-subtitle">We repair all kinds of trucks, pickups, cars, and equipment by highly qualified professional mechanics who are well-trained and experts in Volvo, Mercedes, Man, Scania, and many more.</p>

              </div>
            </div>
          </div>
          <div className="ak-height-100 ak-height-lg-50"></div>
          <div className="gallery__lists">
            <div className="row">

              {/* {
                galleryData?.length > 0 ?
                  galleryData?.map((ele, i) => {
                    return (
                      ele?.image != null ?
                        <div className="col-md-4 mb-3">
                          <div className="position-relative">
                            <a data-fancybox="gallery" href={API_BASE_IMAGE_URL + ele?.image}>
                              <img alt="" src={API_BASE_IMAGE_URL + ele?.thumbnail} />
                            </a>
                            <div className="caption"><h4>{ele?.title}</h4></div>
                          </div>
                        </div> :
                        <div className="col-md-4 mb-3">
                          <div className="position-relative">
                            <a data-fancybox="gallery" href={API_BASE_IMAGE_URL + ele?.video_url}>
                              <img alt="" src={API_BASE_IMAGE_URL + ele?.thumbnail} />
                              <div className="youtubeIcon"><img src="assets/img/youtube.svg" className='youtube' alt="" /></div>
                            </a>
                            <div className="caption"><h4>{ele?.title}</h4></div>
                          </div>

                        </div>
                    )
                  }) : <p>No image/video were added</p>

              } */}
              {galleryLists.slice(0, visibleItems)?.map((ele, i) => (
                <div className="col-md-4 mb-3" key={i}>
                  <div className="position-relative">
                    <a data-fancybox="gallery" href={ele?.img}>
                      <img alt="" src={ele?.img} />
                    </a>
                  </div>
                </div>
              ))}
              {visibleItems < galleryLists.length && (
                <div className="col-md-12 text-center mt-5">
                  <a onClick={loadMoreItems} className="common-btn" style={{cursor:"pointer"}}>Load More</a>
                </div>
              )}




            </div>
          </div>
        </div>
        <div className="ak-height-100 ak-height-lg-50"></div>
      </Fancybox>
    </>
  )
}

export default Gallery