import React from 'react'
import InnerBanner from '../components/InnerBanner/InnerBanner'
import { Link } from 'react-router-dom'

function Services() {

    const services = [
        {
            url: 'assets/img/services/1.jpg',
            title: 'Lube Service',
            desc: <>We use premium oil and have partnered with a major oil supplier in the UAE to
                ensure the highest quality oil for your vehicle. Our comprehensive oil service is
                offered at the best prices in the market. Fleet customers can benefit from our special
                fleet price card for lube services. During every service, we conduct a 24-point inspection to ensure your vehicle's
                optimal performance. Regular periodic service and health check-ups can significantly
                reduce garage visits and prevent major breakdowns.</>,
        },
        {
            url: 'assets/img/services/2.jpg',
            title: 'Mechanical Repair',
            desc: <>From tightening small bolts to fine-tuning engines, we handle it all with precision. Our
            expertise extends to managing all complicated repair works with ease. With highly experienced
            technicians, we are fully equipped to take on any mechanical repair task, no matter the
            complexity. Trust us to deliver exceptional service and get your vehicle back on the road in
            optimal condition</>,
        },
        {
            url: 'assets/img/services/3.jpg',
            title: 'Greasing and washing',
            desc: <>we offer deep washing services, including underbody washing, for vehicles ranging from cars
            to trucks. Our facility can accommodate up to two full body washes and two underbody washes
            simultaneously, ensuring efficient service even during peak times. Trust us to keep your
            vehicles clean and well-maintained, inside and out</>,
        },
        {
            url: 'assets/img/services/4.jpg',
            title: 'FABRICATION/SHEET METAL WORKS',
            desc: 'Specializing in fabrication and sheet metal works, we offer precision engineering and cutting-edge solutions for diverse industries. Our skilled craftsmen utilize state-of-the-art technology to deliver custom designs, prototypes, and mass production with utmost precision and efficiency. Trust us for quality, reliability, and innovation in every project we undertake.',
        },
        {
            url: 'assets/img/services/5.jpg',
            title: 'ELECTRICAL REPAIR',
            desc: 'Skilled electricians provide comprehensive electrical repair services, diagnosing and fixing issues with wiring, circuits, and fixtures. Services include troubleshooting, repairing outlets, switches, and lighting systems, ensuring safety and compliance with regulations. Trust professionals to efficiently restore functionality, preventing hazards and maintaining the integrity of your electrical system.',
        },
        {
            url: 'assets/img/services/6.jpg',
            title: 'COMPUTER DIAGNOSIS & PROGRAMMING',
            desc: 'Computer Diagnosis & Programming services offer comprehensive troubleshooting and optimization solutions for hardware and software issues. Our expert technicians employ advanced diagnostic tools to identify problems swiftly, ensuring efficient repair and enhancement of system performance. From resolving bugs to customizing software, we deliver tailored solutions to meet your computing needs effectively.',
        },
        {
            url: 'assets/img/services/7.jpeg',
            title: 'Hydraulic equipment works',
            desc: 'Hydraulic equipment utilizes fluid power to perform various tasks. These systems rely on the transmission of force through pressurized liquids, typically oil. Pistons, cylinders, valves, and pumps work together to generate, control, and distribute this power, enabling machinery to lift heavy loads, operate precision tools, and execute complex movements with efficiency and reliability.',
        },
        {
            url: 'assets/img/services/8.jpg',
            title: 'Branding & art works',
            desc: "Crafting captivating branding and art is our passion. With meticulous attention to detail, we blend creativity and strategy to tell compelling stories. From logos that resonate to visual identities that captivate, we elevate your brand. Let's create something unforgettable together.",
        },
        {
            url: 'assets/img/services/9.jpg',
            title: 'fork lift repair',
            desc: "Our expert technicians provide comprehensive repair services for forklifts, ensuring optimal performance and safety. From engine diagnostics to hydraulic system maintenance, we handle it all efficiently. With meticulous attention to detail and timely service, we keep your forklifts running smoothly, minimizing downtime and maximizing productivity for your operations.",
        },
        {
            url: 'assets/img/services/10.webp',
            title: 'AC/Chiller repair',
            desc: 'Expert AC/Chiller repair services ensure optimal cooling performance. Our skilled technicians diagnose and fix issues promptly, from compressor faults to refrigerant leaks, ensuring efficient operation. Trust us for timely repairs, preventive maintenance, and superior customer service to keep your AC/Chiller systems running smoothly and your environment comfortable year-round.',
        },
        {
            url: 'assets/img/services/11.jpeg',
            title: 'Auto denting',
            desc: "Restore your vehicle's pristine appearance with our auto denting service. Our skilled technicians utilize advanced techniques to repair dents of all sizes, ensuring a seamless finish. Say goodbye to unsightly blemishes and drive confidently again. Trust us to bring back the allure of your vehicle with precision and expertise.",
        },
        {
            url: 'assets/img/services/12.jpg',
            title: 'Auto Painting',
            desc: "Revitalize your vehicle with our professional auto painting services. Our skilled technicians use premium paints and state-of-the-art equipment to ensure a flawless finish. Whether it's a touch-up or a complete color change, we'll make your car look brand new. Trust us for quality craftsmanship and attention to detail.",
        }
    ]
    return (
        <>
            <InnerBanner title='Services' image='assets/img/about.jpeg' />
            <div className="ak-height-75 ak-height-lg-80"></div>
            <div className="d-flex justify-content-center">
                <div className="sticky-content container">
                    <div className="content style_2">
                        <div className="service">
                            {
                                services?.map((ele, i) => {
                                    return (
                                        <div className="service-card" data-aos="fade-left" key={i}>
                                            <a href="#" className="card-img">
                                                <img src={ele.url} className="ak-bg" alt="..." />
                                            </a>
                                            <div className="card-info">
                                                <a href="#" className="card-title">{ele.title}</a>
                                                <p className="card-desp">{ele.desc}</p>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className="sidebar width-none">
                        <div className="services-content">
                            <div className="ak-section-heading ak-style-1">
                                <div className="background-text" data-aos="fade-right" data-aos-duration="1000">Services</div>
                                <h2 className="ak-section-title">Dedicated<br /> Services</h2>
                                <p className="ak-section-subtitle">Our expert mechanics repair all trucks, pickups, cars, and equipment, specializing in Volvo, Mercedes, MAN, Scania, and more.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="scroll-end-point"></div>

            <div className="ak-height-125 ak-height-lg-80"></div>
            <div className="container">
                <div className="cta" data-aos="fade-right">
                    <span className="border-pr"></span>
                    <span className="border-wh"></span>
                    <div className="cta-info">
                        <h2 className="cta-title" data-aos="fade-left" data-aos-delay="100">Get in touch with our experts</h2>
                        <p className="cta-desp">We repair all kinds of trucks, pickups, cars, and equipment by highly qualified professional mechanics who are well-trained and experts in Volvo, Mercedes, Man, Scania, and many more.</p>
                        <Link to="/contact" className="cta-btn">
                            <img src="assets/img/phone.svg" alt="..." />
                            <span className="ms-2"> GET IN TOUCH WITH US</span>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="ak-height-125 ak-height-lg-80"></div>
        </>
    )
}

export default Services