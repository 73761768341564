import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';

// import required modules
import { Autoplay } from 'swiper/modules';

export default function Clients() {


    return (
        <>
            <div className="ak-height-125 ak-height-lg-80"></div>
            <div className="container">
                <div className="ak-slider ak-trusted-client-slider">
                    <h4 className="title">Trusted Clients</h4>
                    <Swiper
                        loop
                        centeredSlides={true}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        breakpoints={{
                            0: {
                                slidesPerView: 2,
                                spaceBetween: 30,
                            },
                            640: {
                                slidesPerView: 2,
                                spaceBetween: 30,
                            },
                            768: {
                                slidesPerView: 4,
                                spaceBetween: 30,
                            },
                            1024: {
                                slidesPerView: 6,
                                spaceBetween: 30,
                            },
                        }}
                        modules={[Autoplay]}
                        className="mySwiper"
                    >
                        <SwiperSlide>
                            <div className="trusted-client">
                                <img src="assets/img/clients/1.svg" alt="..." />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="trusted-client">
                                <img src="assets/img/clients/2.svg" alt="..." />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="trusted-client">
                                <img src="assets/img/clients/3.svg" alt="..." />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="trusted-client">
                                <img src="assets/img/clients/4.svg" alt="..." />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="trusted-client">
                                <img src="assets/img/clients/5.svg" alt="..." />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="trusted-client">
                                <img src="assets/img/clients/6.svg" alt="..." />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="trusted-client">
                                <img src="assets/img/clients/7.svg" alt="..." />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="trusted-client">
                                <img src="assets/img/clients/8.svg" alt="..." />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="trusted-client">
                                <img src="assets/img/clients/9.svg" alt="..." />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="trusted-client">
                                <img src="assets/img/clients/10.svg" alt="..." />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="trusted-client">
                                <img src="assets/img/clients/11.svg" alt="..." />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="trusted-client">
                                <img src="assets/img/clients/12.svg" alt="..." />
                            </div>
                        </SwiperSlide>

                    </Swiper>

                </div>
            </div>
        </>
    )
}
