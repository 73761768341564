import logo from './logo.svg';
import './App.css';
import './pageStyles.css';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import RouteHandler from './router/router';
import ScrollTop from './components/ScrollTop';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function App() {
  useEffect(() => {
    AOS.init();
  }, [])
  
  return (
    <>
     <ToastContainer />
      <ScrollTop />
      <Header />
      <RouteHandler />
      <Footer />
    </>
  );
}

export default App;
