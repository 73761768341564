import React, { useState } from 'react'
import InnerBanner from '../components/InnerBanner/InnerBanner'
import CountUp from 'react-countup'
import Clients from '../components/Clients/Clients'
import { Link } from 'react-router-dom'
import { Accordion } from 'react-bootstrap'
import Review from '../components/Review/Review'

export default function About() {
    const [activeKey, setActiveKey] = useState('0');

    const handleSelect = (eventKey) => {
      console.log('Selected eventKey:', eventKey);
      setActiveKey(eventKey);
    };
    return (
        <>
            <InnerBanner title='About Us' image='./assets/img/about.jpeg' />
            <div className="ak-height-125 ak-height-lg-80"></div>
            <div className="container">
                <div className="company-tab">
                    <ul className="tabs">
                        <li className="active-tab">Mission</li>
                    </ul>

                    <div className="tabs-content">
                        <div className="list">
                            <div className="ak-section-heading ak-style-1 ak-type-1">
                                <div className="background-text">Mission</div>
                                <h3 className="desp">Pioneering Research and Innovative Insights.</h3>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className="ak-height-125 ak-height-lg-80"></div>
            <section className="container">
                <div className="choose-us-container-extents">
                    <div className="choose-us-contain">
                        <div className="choose-us-info" data-aos="fade-up">
                            <div className="ak-section-heading ak-style-1">
                                <div className="background-text">Feature</div>
                                <h2 className="ak-section-title">About Us</h2>
                                <p className="ak-section-subtitle">Established in 2018, Cruiser Xpress Auto Repairing is your premier destination for comprehensive automotive services, all conveniently housed under one roof. At Cruiser Xpress, we are committed to providing unparalleled quality services and expert advice to our valued partners. Cruiser Group is a professionally managed automobile workshop with the necessary infrastructure and expertise to cater to a wide range of automotive needs, including mechanical, electrical, denting, painting, and more. In just a short span of time, Cruiser Group has emerged as a prominent leader in the field of automobile maintenance and fleet solutions in Dubai. Our state-of-the-art Al Quoz facility boasts a generous 40,000 square feet, equipped with the latest tools and modern jacks to ensure top-notch service. Additionally, our Jebel Ali facility operates within a vast 100,000 square feet space, further enhancing our capabilities. We take pride in being at your service around the clock. With comprehensive service agreements, including Annual Maintenance Contracts (AMC), Preventive Maintenance Contracts (PMC), we have established strong partnerships with numerous major companies. Moreover, our commitment extends to providing 24/7 roadside assistance, exclusively available to our esteemed customers. Cruiser Xpress Auto Repairing is your trusted partner for automotive excellence. Experience the difference with us, where quality, professionalism, and convenience come together seamlessly.</p>
                            </div>
                            <div className="ak-height-60 ak-height-lg-30"></div>
                            <Link to="/contact" className="common-btn">
                                contact us
                            </Link>
                        </div>
                        <div className="choose-us-img" data-aos="fade-up" data-aos-delay="150">
                            <img src="assets/img/choose-us.png" alt="..." />
                            <img className="circle-img" src="assets/img/Circle.svg" alt="..." />
                        </div>
                    </div>
                </div>
            </section>

            <div className="ak-height-75 ak-height-lg-80"></div>
            <div className="container">
                <h4 className="faq-images-title" data-aos="fade-left" data-aos-delay="700">Your Safety, Our Priority :</h4>
                <div className="faq-images">
                    <div className="faq">
                        <Accordion defaultActiveKey="0" className='ak-accordion' onSelect={handleSelect}>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>ACCIDENTAL INSURANCE CLAIMS</Accordion.Header>
                                <Accordion.Body>
                                    We Are Associated With Various Insurance Companies To Support Our Corporate Fleet Based On Their Recommendation To Insurace Company. We Under-take All Kind Of Accidental Repair Works Specially In Trucks And Other Commercial Fleet. We Are Currently Dealing With Rsa, Salama, Noor Takaful, Abudhabi Takaful.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header className='ak-accordion-title'>24/7 MOBILE WORKSHOP FOR CONTRACT FLEET</Accordion.Header>
                                <Accordion.Body>
                                    Moble Workshop And Onsite Service Excellence. Cruiser Xpress Auto Repairing Is Proud To Introduce Our Fully Equipped, Uae Government Approved Mobile Workshop, Designed To Provide 24/7 Roadside Assistance. Our Commitment To Your Convenience Knows No Bonds.
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>

                    </div>
                    <div className="images">
                        {
                            activeKey == '0' ? <img src="assets/img/insurance.jpg" className="img-bg-faq" alt="..." /> : 
                            <img src="assets/img/24-support.jpg" className="img-bg-faq" alt="..." />
                        }
                        
                        <div className="images-info">
                            <div className="d-flex align-items-center gap-3">
                                <div className="heartbeat-icon">
                                    <a href="tel:(406)555-0120">
                                        <span className="ak-heartbeat-btn"><img src="assets/img/phone.svg" alt="..." /></span>
                                    </a>
                                </div>
                                <h3>+97143448443</h3>
                            </div>
                            <div className="d-flex align-items-center gap-3">
                                <div className="heartbeat-icon">
                                    <a href="emailto:info@cruiserexpress.com">
                                        <span className="ak-heartbeat-btn"><img src="assets/img/email.svg" alt="..." /></span>
                                    </a>
                                </div>
                                <h3>info@cruiserexpress.com</h3>
                            </div>

                        </div>
                    </div>
                </div>
            </div>


            <Clients />


            <div className="ak-height-125 ak-height-lg-80"></div>
           <Review/>

        </>
    )
}
