import React from 'react'
import { Link } from 'react-router-dom'

export default function InnerBanner({image, title}) {
  return (
    <div className="ak-bg" style={{backgroundImage:`url(${image})`}}>
    <div className="ak-height-100 ak-height-lg-100"></div>
    <div className="ak-height-125 ak-height-lg-40"></div>
    <div className="container">
        <div className="common-page-title">
            <h3 className="page-title">{title}</h3>
            <div className="d-flex gap-2 align-items-center">
                <p><Link to='/'>Home /</Link></p>
                <p>{title}</p>
            </div>
        </div>
    </div>
    <div className="ak-height-100 ak-height-lg-100"></div>
</div>
  )
}
