import React, { useEffect, useState } from 'react'
import './Header.scss'
import { Link, NavLink } from 'react-router-dom'
import { getAllGallery } from '../../redux/actionCreator'
import { useDispatch, useSelector } from 'react-redux'
import { loader } from '../../redux/commonReducer'
export default function Header() {
    const [menuOpen, setMenuOpen] = useState(false)
    const dispatch = useDispatch()
    const handleMenu = () => {
        setMenuOpen(!menuOpen)
    }

    
    return (
        <>

       
         

            <header className="ak-site_header ak-style1 ak-sticky_header">
                <div className="ak-main_header">
                    <div className="container">
                        <div className="ak-main_header_in">
                            <div className="ak-main-header-left">
                                <Link className="ak-site_branding" to="/">
                                    <img src="assets/img/logo-new.svg" alt="..." />
                                </Link>
                            </div>
                            <div className="ak-main-header-center">
                                <div className="ak-nav ak-medium">
                                    <ul className="ak-nav_list" style={{ display: menuOpen ? 'block' : 'none' }}>
                                        <li>
                                            <NavLink to="/" onClick={handleMenu} className="text-hover-animaiton">Home</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/about" onClick={handleMenu} className="text-hover-animaiton">About</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/services" onClick={handleMenu} className="text-hover-animaiton">Services</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/gallery" onClick={handleMenu} className="text-hover-animaiton">Gallery</NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="/contact"  onClick={handleMenu} className="text-hover-animaiton">Contact</NavLink>
                                        </li>
                                    </ul>
                                    <span onClick={handleMenu} className={menuOpen ? 'ak-munu_toggle ak-toggle_active' : "ak-munu_toggle"}><span></span></span>
                                </div>

                            </div>
                            <div className="ak-main-header-right">
                                <a href="tel:+97143448443">
                                    <div className="d-flex align-items-center gap-3">
                                        <div className="heartbeat-icon">
                                            <span className="ak-heartbeat-btn">
                                                <img src="assets/img/phone.svg" alt="..." /></span>
                                        </div>
                                        <h6>+971 4 344 8443</h6>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="nav-bar-border"></div>
            </header>
        </>

    )
}
